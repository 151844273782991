import { Link } from '@remix-run/react'
import { Badge } from '@repo/ui/components/Badge.js'
import type { ComponentProps } from 'react'
import { UserAvatar } from './UserAvatar'

export const UserBadge = ({
  userId,
  displayName,
  avatarUrl,
  ...props
}: {
  displayName: string
  userId: string
  avatarUrl: string | null
} & ComponentProps<typeof Badge>) => {
  return (
    <Link to={`/user/${userId}`}>
      <Badge
        className="gap-1 font-medium hover:underline"
        variant="violet"
        {...props}
      >
        <UserAvatar src={avatarUrl} className="size-4" />
        <span className="max-w-48 truncate">{displayName}</span>
      </Badge>
    </Link>
  )
}
